var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-header row" }, [
      _c("div", { staticClass: "content-header-left col-md-7 col-12 mb-2" }, [
        _c("div", { staticClass: "row breadcrumbs-top" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "content-header-title float-left mb-0" }, [
              _vm._v(
                _vm._s(_vm.trans("efilling.efilling")) +
                  "\n                    "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "breadcrumb-wrapper col-12" }, [
              _c("ol", { staticClass: "breadcrumb" }, [
                _c(
                  "li",
                  { staticClass: "breadcrumb-item" },
                  [
                    _c("router-link", { attrs: { to: "/home" } }, [
                      _vm._v(
                        "\n                                    " +
                          _vm._s(_vm.trans("general.home")) +
                          "\n                                "
                      )
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "breadcrumb-item" },
                  [
                    _c("router-link", { attrs: { to: "/efilling" } }, [
                      _vm._v(
                        "\n                                    " +
                          _vm._s(_vm.trans("efilling.efilling")) +
                          "\n                                "
                      )
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c("li", { staticClass: "breadcrumb-item active" }, [
                  _vm._v(
                    "\n                                " +
                      _vm._s(_vm.trans("efilling.efilling_details")) +
                      "\n                            "
                  )
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "content-header-right text-md-right col-md-5 col-12 d-md-block d-none"
        },
        [
          _c("div", { staticClass: "form-group breadcrum-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-sm pull-right ml-1",
                on: {
                  click: function($event) {
                    return _vm.$router.push("/e-filling/create")
                  }
                }
              },
              [
                _c("i", { staticClass: "feather icon-plus" }),
                _vm._v(" " + _vm._s(_vm.trans("efilling.create_efilling")))
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-sm pull-right ml-1",
                on: {
                  click: function($event) {
                    return _vm.$router.push("/e-filling")
                  }
                }
              },
              [
                _c("i", { staticClass: "feather icon-list" }),
                _vm._v(" " + _vm._s(_vm.trans("efilling.efilling_list")))
              ]
            )
          ])
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-body" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-header" }, [
          _c("h4", { staticClass: "card-title float-left mb-0" }, [
            _vm._v(_vm._s(_vm.trans("efilling.efilling_details")))
          ]),
          _vm._v(" "),
          _vm.efilling.status == 0 && _vm.hasPermission("approve-e-filling")
            ? _c(
                "button",
                {
                  staticClass:
                    "btn btn-success btn-sm mr-1 mb-1 waves-effect waves-light pull-right",
                  on: { click: _vm.approve }
                },
                [
                  _c("i", { staticClass: "feather icon-copy" }),
                  _vm._v(
                    " " + _vm._s(_vm.trans("efilling.approve_this_efilling"))
                  )
                ]
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-content" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c("div", { staticClass: "card" }, [
                      _vm.userRole != "user"
                        ? _c("div", { staticClass: "card-header" }, [
                            _c("h4", { staticClass: "card-title" }, [
                              _vm._v(
                                "Personal Income Tax Self Assessment Returns"
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "card-body" }, [
                        _vm.userRole != "user"
                          ? _c("div", { staticClass: "row" }, [
                              _c(
                                "ul",
                                {
                                  staticClass:
                                    "list-group list-group-vertical-sm m-t-20"
                                },
                                [
                                  _c("li", { staticClass: "list-group-item" }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.trans("efilling.full_name")
                                        ) + ":"
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("strong", [
                                      _vm._v(_vm._s(_vm.efilling.full_name))
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("li", { staticClass: "list-group-item" }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.trans("efilling.tin")) + ":"
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("strong", [
                                      _vm._v(_vm._s(_vm.efilling.tin))
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("li", { staticClass: "list-group-item" }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.trans("efilling.email")) +
                                          ":"
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("strong", [
                                      _vm._v(_vm._s(_vm.efilling.email))
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("li", { staticClass: "list-group-item" }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.trans("efilling.phone")) +
                                          ":"
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("strong", [
                                      _vm._v(_vm._s(_vm.efilling.phone))
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("li", { staticClass: "list-group-item" }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.trans(
                                            "efilling.residential_address"
                                          )
                                        ) + ":"
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(_vm.efilling.residential_address)
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("li", { staticClass: "list-group-item" }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.trans("efilling.business_address")
                                        ) + ":"
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(_vm.efilling.business_address)
                                      )
                                    ])
                                  ])
                                ]
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("h4", { staticClass: "card-title" }, [
                          _vm._v("Income")
                        ]),
                        _c("br"),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "ul",
                            {
                              staticClass:
                                "list-group list-group-vertical-sm m-t-20"
                            },
                            [
                              _c("li", { staticClass: "list-group-item" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.trans("efilling.category")) + ":"
                                  )
                                ]),
                                _vm._v(" "),
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.efilling.category.name))
                                ])
                              ]),
                              _vm._v(" "),
                              _c("li", { staticClass: "list-group-item" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.trans("efilling.sub_category")) +
                                      ":"
                                  )
                                ]),
                                _vm._v(" "),
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.efilling.sub_category.name))
                                ])
                              ]),
                              _vm._v(" "),
                              _c("li", { staticClass: "list-group-item" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.trans("efilling.total_income")) +
                                      ":"
                                  )
                                ]),
                                _vm._v(" "),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatMoney")(
                                        _vm.efilling.total_income
                                      )
                                    )
                                  )
                                ])
                              ])
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("h4", { staticClass: "card-title" }, [
                          _vm._v("Deduction of Income")
                        ]),
                        _c("br"),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "ul",
                            {
                              staticClass:
                                "list-group list-group-vertical-sm m-t-20"
                            },
                            [
                              _c("li", { staticClass: "list-group-item" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans(
                                        "efilling.national_housing_fund"
                                      )
                                    ) + ":"
                                  )
                                ]),
                                _vm._v(" "),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatMoney")(
                                        _vm.efilling.national_housing_fund
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("li", { staticClass: "list-group-item" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans(
                                        "efilling.national_pension_scheme"
                                      )
                                    ) + ":"
                                  )
                                ]),
                                _vm._v(" "),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatMoney")(
                                        _vm.efilling.national_pension_scheme
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("li", { staticClass: "list-group-item" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans("efilling.life_insurance")
                                    ) + ":"
                                  )
                                ]),
                                _vm._v(" "),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatMoney")(
                                        _vm.efilling.life_insurance
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("li", { staticClass: "list-group-item" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.trans("efilling.wht")) + ":"
                                  )
                                ]),
                                _vm._v(" "),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatMoney")(_vm.efilling.wht)
                                    )
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("li", { staticClass: "list-group-item" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans("efilling.total_deduction")
                                    ) + ":"
                                  )
                                ]),
                                _vm._v(" "),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatMoney")(
                                        _vm.efilling.total_deduction
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("li", { staticClass: "list-group-item" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans("efilling.chargable_income")
                                    ) + ":"
                                  )
                                ]),
                                _vm._v(" "),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatMoney")(
                                        _vm.efilling.chargable_income
                                      )
                                    )
                                  )
                                ])
                              ])
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-8 offset-md-4" }, [
                            _vm.efilling.status == 0 &&
                            _vm.hasPermission("approve-e-filling")
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-success mr-1 mb-1 waves-effect waves-light pull-right",
                                    on: { click: _vm.approve }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.trans(
                                            "efilling.approve_this_efilling"
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-primary waves-effect waves-light pull-right mr-1 mb-1",
                                on: { click: _vm.compute }
                              },
                              [_c("span", [_vm._v("Compute Tax")])]
                            )
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _vm.isModalVisible
      ? _c(
          "div",
          {
            staticClass: "modal fade",
            attrs: {
              id: "modal",
              tabindex: "-1",
              role: "dialog",
              "aria-labelledby": "paymentDetail",
              "aria-hidden": "true"
            }
          },
          [
            _c("div", { staticClass: "modal-dialog modal-lg" }, [
              _c("div", { staticClass: "modal-content" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "modal-body", attrs: { id: "printable" } },
                  [
                    _c("img", {
                      staticClass: "center-logo",
                      attrs: {
                        src: _vm.getMainLogo,
                        width: "100px",
                        alt: "branding logo"
                      }
                    }),
                    _vm._v(" "),
                    _c("h6", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.trans("efilling.full_name")) +
                            ": " +
                            _vm._s(_vm.efilling.full_name)
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("h6", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.trans("efilling.tin")) +
                            ": " +
                            _vm._s(_vm.efilling.tin)
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("h6", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.trans("efilling.email")) +
                            ": " +
                            _vm._s(_vm.efilling.email)
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("h6", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.trans("efilling.phone")) +
                            ": " +
                            _vm._s(_vm.efilling.phone)
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("h6", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.trans("efilling.residential_address")) +
                            ": " +
                            _vm._s(_vm.efilling.residential_address)
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("h6", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.trans("efilling.business_address")) +
                            ": " +
                            _vm._s(_vm.efilling.business_address)
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("h4", { staticClass: "text-center" }, [
                      _vm._v("AMOUNT PAYABLE")
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "Reference to your e-filling and submission the following is the amount payable "
                      ),
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatMoney")(
                              _vm.efilling.payable_tax_amount
                            )
                          )
                        )
                      ]),
                      _vm._v(
                        " Kindly pay your tax by visiting any bank branch close to you or visit our Online Revenue Collection and Payment Platform that is "
                      ),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://epay.irs.kt.gov.ng/invoice",
                            target: "_blank"
                          }
                        },
                        [_vm._v("https://epay.irs.kt.gov.ng")]
                      ),
                      _vm._v(" and make your Payment.")
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("p", [_vm._v("Thank you")])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "modal-footer" }, [
                  _vm._m(1),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary waves-effect text-left",
                      attrs: { type: "button" },
                      on: { click: _vm.printMe }
                    },
                    [
                      _c("i", { staticClass: "feather icon-printer" }),
                      _vm._v(" Print")
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-info waves-effect text-left",
                      attrs: { type: "button" },
                      on: { click: _vm.sendEmail }
                    },
                    [
                      _c("i", { staticClass: "feather icon-mail" }),
                      _vm._v(" Send Email")
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger waves-effect text-left",
                      attrs: { type: "button", "data-dismiss": "modal" }
                    },
                    [
                      _c("i", { staticClass: "feather icon-cancel" }),
                      _vm._v(_vm._s(_vm.trans("general.close")))
                    ]
                  )
                ])
              ])
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title", attrs: { id: "paymentDetail" } }, [
        _vm._v("Tax Details")
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-hidden": "true"
          }
        },
        [_vm._v("×")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "btn btn-success waves-effect text-left",
        attrs: { href: "https://epay.irs.kt.gov.ng/invoice", target: "_blank" }
      },
      [
        _c("i", { staticClass: "feather icon-dollar-sign" }),
        _vm._v(" Pay Now\n                        ")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }