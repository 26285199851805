var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.proceed.apply(null, arguments)
        },
        keydown: function($event) {
          return _vm.annualReturnForm.errors.clear($event.target.name)
        }
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-xl-12 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("annual_return.name_of_organization")))
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.annualReturnForm.name_of_organization,
                    expression: "annualReturnForm.name_of_organization"
                  }
                ],
                staticClass: "form-control",
                class: {
                  "is-invalid": _vm.annualReturnForm.errors.has(
                    "name_of_organization"
                  )
                },
                attrs: {
                  type: "text",
                  value: "",
                  name: "name_of_organization",
                  placeholder: _vm.trans("annual_return.name_of_organization")
                },
                domProps: { value: _vm.annualReturnForm.name_of_organization },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.annualReturnForm,
                      "name_of_organization",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.annualReturnForm,
                  "prop-name": "name_of_organization"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-12 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("annual_return.email_address")))
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.annualReturnForm.email,
                    expression: "annualReturnForm.email"
                  }
                ],
                staticClass: "form-control",
                class: {
                  "is-invalid": _vm.annualReturnForm.errors.has("email")
                },
                attrs: {
                  type: "text",
                  value: "",
                  name: "email",
                  placeholder: _vm.trans("annual_return.email_address")
                },
                domProps: { value: _vm.annualReturnForm.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.annualReturnForm, "email", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.annualReturnForm,
                  "prop-name": "email"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-12 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("annual_return.tin")))
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.annualReturnForm.tin,
                    expression: "annualReturnForm.tin"
                  }
                ],
                staticClass: "form-control",
                class: { "is-invalid": _vm.annualReturnForm.errors.has("tin") },
                attrs: {
                  type: "text",
                  value: "",
                  name: "tin",
                  placeholder: _vm.trans("annual_return.tin")
                },
                domProps: { value: _vm.annualReturnForm.tin },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.annualReturnForm, "tin", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: { "form-name": _vm.annualReturnForm, "prop-name": "tin" }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-12 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("annual_return.year_of_return")))
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.annualReturnForm.year_of_return,
                    expression: "annualReturnForm.year_of_return"
                  }
                ],
                staticClass: "form-control",
                class: {
                  "is-invalid": _vm.annualReturnForm.errors.has(
                    "year_of_return"
                  )
                },
                attrs: {
                  type: "text",
                  value: "",
                  name: "year_of_return",
                  placeholder: _vm.trans("annual_return.year_of_return")
                },
                domProps: { value: _vm.annualReturnForm.year_of_return },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.annualReturnForm,
                      "year_of_return",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.annualReturnForm,
                  "prop-name": "year_of_return"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-12 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("annual_return.date")))
              ]),
              _vm._v(" "),
              _c("datepicker", {
                class: {
                  "form-control is-invalid": _vm.annualReturnForm.errors.has(
                    "date"
                  )
                },
                attrs: {
                  bootstrapStyling: true,
                  placeholder: _vm.trans("admission.date")
                },
                on: {
                  selected: function($event) {
                    return _vm.annualReturnForm.errors.clear("date")
                  }
                },
                model: {
                  value: _vm.annualReturnForm.date,
                  callback: function($$v) {
                    _vm.$set(_vm.annualReturnForm, "date", $$v)
                  },
                  expression: "annualReturnForm.date"
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.annualReturnForm,
                  "prop-name": "date"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("file-upload-input", {
              attrs: {
                "button-text": "Chose File",
                token: _vm.annualReturnForm.upload_token,
                module: "annual_return",
                "clear-file": _vm.clear_message_attachment,
                "module-id": _vm.annualReturnForm.id,
                "has-file-type": true
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-8 offset-md-4" },
          [
            _c(
              "button",
              {
                staticClass:
                  "btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right",
                attrs: { type: "submit" }
              },
              [
                _vm.id
                  ? _c("span", [_vm._v(_vm._s(_vm.trans("general.update")))])
                  : _c("span", [_vm._v(_vm._s(_vm.trans("general.save")))])
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass:
                  "btn btn-danger waves-effect waves-light pull-right mr-1 mb-1",
                attrs: { to: "/annual-return" }
              },
              [_vm._v(_vm._s(_vm.trans("general.cancel")) + "\n            ")]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }