<template>
    <form @submit.prevent="proceed" @keydown="annualReturnForm.errors.clear($event.target.name)">
        <div class="row">
            <div class="col-xl-12 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('annual_return.name_of_organization')}}</label>
                    <input class="form-control" :class="{ 'is-invalid': annualReturnForm.errors.has('name_of_organization') }" type="text" value="" v-model="annualReturnForm.name_of_organization" name="name_of_organization" :placeholder="trans('annual_return.name_of_organization')">
                    <show-error :form-name="annualReturnForm" prop-name="name_of_organization"></show-error>
                </div>
            </div>

            <div class="col-xl-12 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('annual_return.email_address')}}</label>
                    <input class="form-control" :class="{ 'is-invalid': annualReturnForm.errors.has('email') }" type="text" value="" v-model="annualReturnForm.email" name="email" :placeholder="trans('annual_return.email_address')">
                    <show-error :form-name="annualReturnForm" prop-name="email"></show-error>
                </div>
            </div>

            <div class="col-xl-12 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('annual_return.tin')}}</label>
                    <input class="form-control" :class="{ 'is-invalid': annualReturnForm.errors.has('tin') }" type="text" value="" v-model="annualReturnForm.tin" name="tin" :placeholder="trans('annual_return.tin')">
                    <show-error :form-name="annualReturnForm" prop-name="tin"></show-error>
                </div>
            </div>
            
            <div class="col-xl-12 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('annual_return.year_of_return')}}</label>
                    <input class="form-control" :class="{ 'is-invalid': annualReturnForm.errors.has('year_of_return') }" type="text" value="" v-model="annualReturnForm.year_of_return" name="year_of_return" :placeholder="trans('annual_return.year_of_return')">
                    <show-error :form-name="annualReturnForm" prop-name="year_of_return"></show-error>
                </div>
            </div>
            
            <div class="col-xl-12 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('annual_return.date')}}</label>
                    <datepicker v-model="annualReturnForm.date" :bootstrapStyling="true" @selected="annualReturnForm.errors.clear('date')"  :class="{ 'form-control is-invalid': annualReturnForm.errors.has('date') }" :placeholder="trans('admission.date')"></datepicker>
                    <show-error :form-name="annualReturnForm" prop-name="date"></show-error>
                </div>
            </div>

            <div class="form-group">
            <file-upload-input :button-text="'Chose File'" :token="annualReturnForm.upload_token" module="annual_return" :clear-file="clear_message_attachment" :module-id="annualReturnForm.id" :has-file-type="true"></file-upload-input>
        </div>                              
        </div>
        <div class="row">
            <div class="col-md-8 offset-md-4">
                <button type="submit" class="btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right">
                <span v-if="id">{{trans('general.update')}}</span>
                <span v-else>{{trans('general.save')}}</span>
                </button>

                <router-link to="/annual-return" class="btn btn-danger waves-effect waves-light pull-right mr-1 mb-1">{{trans('general.cancel')}}
                </router-link>

            </div>
        </div>
    </form>
</template>


<script>
    import uuid from 'uuid/v4'
    import datepicker from 'vuejs-datepicker'
    import fileUploadInput from '../../components/file-upload-input'

    export default {
        components: {datepicker, fileUploadInput},
        props: ['uuid'],
        data() {
            return {
                annualReturnForm: new Form({
                    id: '',
                    name_of_organization: '',
                    tin: '',
                    year_of_return: '',
                    date: '',
                    email: '',
                    upload_token: ''
                }),
                clear_message_attachment: false,
            };
        },
        props: ['id'],
        mounted() {
            this.annualReturnForm.upload_token = uuid();
            if(this.id)
                this.getAnnualReturn();
            this.fetchPreRequisites();
        },
        methods: {
            fetchPreRequisites(){
                axios.get('/api/user/pre-requisite')
                    .then(response => response.data)
                    .then(response => {
                        console.log(response)
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error)
                    });
            },
            proceed(){
                if(this.id)
                    this.updateAnnualReturn();
                else
                    this.storeAnnualReturn();
            },
            storeAnnualReturn(){
                this.annualReturnForm.date = moment(this.annualReturnForm.date).format('YYYY-MM-DD');
                this.annualReturnForm.post('/api/annual-return')
                    .then(response => {
                        toastr.success(response.message);
                        this.$router.push('/annual-return');
                    })
                    .catch(error => {
                        toastr.error(error.message);
                        helper.showErrorMsg(error);
                    });
            },
            getAnnualReturn(){
                axios.get('/api/annual-return/'+this.id)
                    .then(response => response.data)
                    .then(response => {
                        this.annualReturnForm.id = response.annualReturn.id;
                        this.annualReturnForm.name_of_organization = response.annualReturn.name_of_organization;
                        this.annualReturnForm.tin = response.annualReturn.tin;
                        this.annualReturnForm.year_of_return = response.annualReturn.year_of_return;
                        this.annualReturnForm.date = response.annualReturn.date;
                        this.annualReturnForm.email = response.annualReturn.email;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                        this.$router.push('/annual-return');
                    });
            },
            updateAnnualReturn(){
                this.annualReturnForm.date = moment(this.annualReturnForm.date).format('YYYY-MM-DD');
                this.annualReturnForm.patch('/api/annual-return/'+this.id)
                    .then(response => {
                        toastr.success(response.message);
                        this.$router.push('/annual-return');
                    })
                    .catch(error => {
                        toastr.error(error.message);
                        helper.showErrorMsg(error);
                    });
            }
           
        }
    }
</script>
