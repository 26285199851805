var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "content-body" }, [
      _c("section", { attrs: { id: "dashboard-ecommerce" } }, [
        _vm.userRole == "admin"
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-3 col-sm-6 col-12" }, [
                _c("div", { staticClass: "card" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-header d-flex flex-column align-items-start pb-0"
                    },
                    [
                      _vm._m(1),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { staticClass: "primary", attrs: { to: "/e-filling" } },
                        [
                          _c("h2", { staticClass: "text-bold-700 mt-1" }, [
                            _vm._v("0")
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [_vm._v("E-Filing")])
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-lg-3 col-sm-6 col-12" }, [
                _c("div", { staticClass: "card" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-header d-flex flex-column align-items-start pb-0"
                    },
                    [
                      _vm._m(2),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "success",
                          attrs: { to: "/annual-return" }
                        },
                        [
                          _c("h2", { staticClass: "text-bold-700 mt-1" }, [
                            _vm._v("0")
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v("Annual Return")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-lg-3 col-sm-6 col-12" }, [
                _c("div", { staticClass: "card" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-header d-flex flex-column align-items-start pb-0"
                    },
                    [
                      _vm._m(3),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { staticClass: "danger", attrs: { to: "/staff" } },
                        [
                          _c("h2", { staticClass: "text-bold-700 mt-1" }, [
                            _vm._v(_vm._s(_vm.staffs))
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [_vm._v("Staffs")])
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-lg-3 col-sm-6 col-12" }, [
                _c("div", { staticClass: "card" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-header d-flex flex-column align-items-start pb-0"
                    },
                    [
                      _vm._m(4),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { staticClass: "warning", attrs: { to: "/user" } },
                        [
                          _c("h2", { staticClass: "text-bold-700 mt-1" }, [
                            _vm._v(_vm._s(_vm.users))
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [_vm._v("Users")])
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.userRole == "staff"
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-4 col-sm-6 col-12" }, [
                _c("div", { staticClass: "card" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-header d-flex flex-column align-items-start pb-0"
                    },
                    [
                      _vm._m(5),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { staticClass: "primary", attrs: { to: "/e-filling" } },
                        [
                          _c("h2", { staticClass: "text-bold-700 mt-1" }, [
                            _vm._v("0")
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [_vm._v("E-Filing")])
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-lg-4 col-sm-6 col-12" }, [
                _c("div", { staticClass: "card" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-header d-flex flex-column align-items-start pb-0"
                    },
                    [
                      _vm._m(6),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "success",
                          attrs: { to: "/annual-return" }
                        },
                        [
                          _c("h2", { staticClass: "text-bold-700 mt-1" }, [
                            _vm._v("0")
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v("Annual Return")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-lg-4 col-sm-6 col-12" }, [
                _c("div", { staticClass: "card" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-header d-flex flex-column align-items-start pb-0"
                    },
                    [
                      _vm._m(7),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { staticClass: "warning", attrs: { to: "/user" } },
                        [
                          _c("h2", { staticClass: "text-bold-700 mt-1" }, [
                            _vm._v(_vm._s(_vm.users))
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [_vm._v("Users")])
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.userRole == "user"
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-6 col-sm-6 col-12" }, [
                _c("div", { staticClass: "card" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-header d-flex flex-column align-items-start pb-0"
                    },
                    [
                      _vm._m(8),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { staticClass: "primary", attrs: { to: "/e-filling" } },
                        [
                          _c("h2", { staticClass: "text-bold-700 mt-1" }, [
                            _vm._v(_vm._s(_vm.efillings))
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [_vm._v("E-Filing")])
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-lg-6 col-sm-6 col-12" }, [
                _c("div", { staticClass: "card" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-header d-flex flex-column align-items-start pb-0"
                    },
                    [
                      _vm._m(9),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "success",
                          attrs: { to: "/annual-return" }
                        },
                        [
                          _c("h2", { staticClass: "text-bold-700 mt-1" }, [
                            _vm._v(_vm._s(_vm.annual_returns))
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v("Annual Return")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.userRole == "admin" || _vm.userRole == "staff"
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-8 col-md-6 col-12" }, [
                _c("div", { staticClass: "card" }, [
                  _vm._m(10),
                  _vm._v(" "),
                  _c("div", { staticClass: "card-content" }, [
                    _c(
                      "div",
                      { staticClass: "card-body pb-0" },
                      [
                        _c("vue-chart", {
                          attrs: { type: "bar", data: _vm.efillingsChart }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-lg-4 col-md-6 col-12" }, [
                _c("div", { staticClass: "card" }, [
                  _vm._m(11),
                  _vm._v(" "),
                  _c("div", { staticClass: "card-content" }, [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "mb-3" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "chart-info d-flex justify-content-between mb-1"
                        },
                        [
                          _vm._m(12),
                          _vm._v(" "),
                          _c("div", { staticClass: "product-result" }, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.approved_annual_reports))
                            ])
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "chart-info d-flex justify-content-between mb-75"
                        },
                        [
                          _vm._m(13),
                          _vm._v(" "),
                          _c("div", { staticClass: "product-result" }, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.pending_annual_reports))
                            ])
                          ])
                        ]
                      )
                    ])
                  ])
                ])
              ])
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content-header row" }, [
      _c("div", { staticClass: "content-header-left col-md-9 col-12 mb-2" }, [
        _c("div", { staticClass: "row breadcrumbs-top" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "content-header-title float-left mb-0" }, [
              _vm._v("Home")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "breadcrumb-wrapper col-12" }, [
              _c("ol", { staticClass: "breadcrumb" }, [
                _c("li", { staticClass: "breadcrumb-item" }, [
                  _c("a", { attrs: { href: "/" } }, [_vm._v("Dashboard")])
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "breadcrumb-item active" }, [
                  _vm._v(
                    "\n                                 Home\n                             "
                  )
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "content-header-right text-md-right col-md-3 col-12 d-md-block d-none"
        },
        [
          _c("div", { staticClass: "form-group breadcrum-right" }, [
            _c("div", { staticClass: "dropdown" }, [
              _c(
                "button",
                {
                  staticClass:
                    "btn-icon btn btn-primary btn-round btn-sm dropdown-toggle",
                  attrs: {
                    type: "button",
                    "data-toggle": "dropdown",
                    "aria-haspopup": "true",
                    "aria-expanded": "false"
                  }
                },
                [_c("i", { staticClass: "feather icon-settings" })]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "dropdown-menu dropdown-menu-right" }, [
                _c(
                  "a",
                  { staticClass: "dropdown-item", attrs: { href: "#" } },
                  [_vm._v("Chat")]
                ),
                _c(
                  "a",
                  { staticClass: "dropdown-item", attrs: { href: "#" } },
                  [_vm._v("Email")]
                ),
                _c(
                  "a",
                  { staticClass: "dropdown-item", attrs: { href: "#" } },
                  [_vm._v("Calendar")]
                )
              ])
            ])
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "avatar bg-rgba-primary p-50 m-0" }, [
      _c("div", { staticClass: "avatar-content" }, [
        _c("i", { staticClass: "fa fa-building text-primary font-medium-5" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "avatar bg-rgba-success p-50 m-0" }, [
      _c("div", { staticClass: "avatar-content" }, [
        _c("i", { staticClass: "fa fa-desktop text-success font-medium-5" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "avatar bg-rgba-danger p-50 m-0" }, [
      _c("div", { staticClass: "avatar-content" }, [
        _c("i", { staticClass: "feather icon-users text-danger font-medium-5" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "avatar bg-rgba-warning p-50 m-0" }, [
      _c("div", { staticClass: "avatar-content" }, [
        _c("i", { staticClass: "fa fa-ticket text-warning font-medium-5" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "avatar bg-rgba-primary p-50 m-0" }, [
      _c("div", { staticClass: "avatar-content" }, [
        _c("i", { staticClass: "fa fa-building text-primary font-medium-5" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "avatar bg-rgba-success p-50 m-0" }, [
      _c("div", { staticClass: "avatar-content" }, [
        _c("i", { staticClass: "fa fa-desktop text-success font-medium-5" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "avatar bg-rgba-warning p-50 m-0" }, [
      _c("div", { staticClass: "avatar-content" }, [
        _c("i", { staticClass: "fa fa-ticket text-warning font-medium-5" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "avatar bg-rgba-primary p-50 m-0" }, [
      _c("div", { staticClass: "avatar-content" }, [
        _c("i", { staticClass: "fa fa-building text-primary font-medium-5" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "avatar bg-rgba-success p-50 m-0" }, [
      _c("div", { staticClass: "avatar-content" }, [
        _c("i", { staticClass: "fa fa-desktop text-success font-medium-5" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "card-header d-flex justify-content-between align-items-end"
      },
      [
        _c("h4", { staticClass: "card-title" }, [_vm._v("E-Filing")]),
        _vm._v(" "),
        _c("p", { staticClass: "font-medium-5 mb-0" }, [
          _c("i", {
            staticClass: "feather icon-settings text-muted cursor-pointer"
          })
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "card-header d-flex justify-content-between pb-0" },
      [
        _c("h4", [_vm._v("Annual Return")]),
        _vm._v(" "),
        _c("div", { staticClass: "dropdown chart-dropdown" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-sm border-0 dropdown-toggle p-0",
              attrs: {
                type: "button",
                id: "dropdownItem2",
                "data-toggle": "dropdown",
                "aria-haspopup": "true",
                "aria-expanded": "false"
              }
            },
            [
              _vm._v(
                "\n                             Last 7 Days\n                           "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dropdown-menu dropdown-menu-right",
              attrs: { "aria-labelledby": "dropdownItem2" }
            },
            [
              _c("a", { staticClass: "dropdown-item", attrs: { href: "#" } }, [
                _vm._v("Last 28 Days")
              ]),
              _vm._v(" "),
              _c("a", { staticClass: "dropdown-item", attrs: { href: "#" } }, [
                _vm._v("Last Month")
              ]),
              _vm._v(" "),
              _c("a", { staticClass: "dropdown-item", attrs: { href: "#" } }, [
                _vm._v("Last Year")
              ])
            ]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "series-info d-flex align-items-center" }, [
      _c("i", { staticClass: "fa fa-circle-o text-bold-700 text-primary" }),
      _vm._v(" "),
      _c("span", { staticClass: "text-bold-600 ml-50" }, [_vm._v("Approved")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "series-info d-flex align-items-center" }, [
      _c("i", { staticClass: "fa fa-circle-o text-bold-700 text-danger" }),
      _vm._v(" "),
      _c("span", { staticClass: "text-bold-600 ml-50" }, [_vm._v("Pending")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }